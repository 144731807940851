import React from 'react'
import image1 from "../assets/image1.png"
import image2 from "../assets/image2.png"
import image3 from "../assets/image3.png"
import image4 from "../assets/image3.png"
import imageplan from "../assets/imageplan.png"
import { Link } from 'react-router-dom'



export default function ApresentationManagers() {



    return (

        <div className='flex flex-col gap-10'>
            <div className=" bg-cyan-500 px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
                    <h2 className="text-2xl text-white font-bold md:text-4xl md:leading-tight">
                        Veja mais detalhes sobre as vantagens do FonotherApp!
                    </h2>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">

                    <div className="bg-pink-500  rounded-tr-[50px]  rounded-bl-[50px]  p-4 text-center">
                        <img src={image1} alt="Plano Terapêutico Individualizado" className="rounded-tr-[50px] rounded-bl-[50px] object-cover mb-4 mx-auto" />
                        <h3 className="text-white font-bold text-lg">Plano Terapêutico Individualizado</h3>
                        <p className="text-white">Planeje sessões adequadas às necessidades específicas de cada paciente</p>
                    </div>


                    <div className="bg-pink-500 rounded-tr-[50px]  rounded-bl-[50px] p-4 text-center">
                        <img src={image2} alt="Planejamento Financeiro" className="rounded-tr-[50px] rounded-bl-[50px] mb-4 mx-auto" />
                        <h3 className="text-white font-bold text-lg">Planejamento Financeiro</h3>
                        <p className="text-white">Você terá acesso a uma planilha onde poderá fazer o planejamento financeiro e poderá facilmente emitir recibos.</p>
                    </div>


                    <div className="bg-pink-500 rounded-tr-[50px]  rounded-bl-[50px] p-4 text-center">
                        <img src={image3} alt="Consultoria Especializada" className="rounded-tr-[50px] rounded-bl-[50px] mb-4 mx-auto" />
                        <h3 className="text-white font-bold text-lg">Consultoria Especializada</h3>
                        <p className="text-white">Você poderá realizar consultoria especializada com profissionais especialistas e com vasta experiência na área de disfagia.</p>
                    </div>


                    <div className="bg-pink-500 rounded-tr-[50px]  rounded-bl-[50px] p-4 text-center">
                        <img src={image4} alt="Emissão de Relatórios" className="rounded-tr-[50px] rounded-bl-[50px] mb-4 mx-auto" />
                        <h3 className="text-white font-bold text-lg">Emissão de Relatórios</h3>
                        <p className="text-white">Emita relatórios de avaliação e acompanhamento que podem ser úteis para encaminhamentos e discussões com a equipe multiprofissional.</p>
                    </div>
                </div>
            </div>



            <div className=" bg-white px-4 ">
                <div className="xl:max-w-1/2  max-w-4xl  flex items-center justify-center mx-auto text-center mb-10 lg:mb-14">
                    <img src={imageplan} alt="" />
                </div>

                <div className="flex items-center justify-center flex-col">
                    <p className="text-white font-medium text-2xl">
                        Quer trazer facilidade para os seus atendimentos diários?
                    </p>
                    <Link to={"/precos"} className='bg-pink-600 p-5 mb-10 text-white rounded-xl hover:bg-pink-700 '>

                        Quer saber como ele funciona na prática?

                        Clique aqui e faça o cadastro no Plano Gratuito!
                    </Link>

                </div>
            </div>





            {false && <div className=" bg-white px-4 ">
                <p className="text-white font-medium text-2xl">
                    Quer trazer facilidade para os seus atendimentos diários?
                </p>

                <div className="flex items-center justify-center flex-col">
                    <p className="text-white font-medium text-2xl">
                        Quer trazer facilidade para os seus atendimentos diários?
                    </p>
                    <Link to={"/precos"} className='bg-pink-600 p-5 mb-10 text-white rounded-xl hover:bg-pink-700 '>

                        Quer saber como ele funciona na prática?

                        Clique aqui e faça o cadastro no Plano Gratuito!
                    </Link>

                </div>
            </div>}






        </div>


    )
}

import React from 'react'
import preLogin2 from "../assets/prelogin2.png"
import { Link } from 'react-router-dom'

export default function CardsInformationsPlan2() {
    return (

        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="flex justify-center align-center  flex-col sm:flex-row">

                <div className="w-full flex sm:mt-10 items-center justify-center md:max-w-2xl border-0 mx-auto text-center  h-96">
                    <img className="w-full bg- h-full object-cover top-6 md:h-[500px]" src={preLogin2} alt="description" />
                </div>


                <div className=" w-full h-full flex items-center justify-center md:max-w-2xl border-0 mx-auto text-center mb-1">

                    <div className=''>
                        <span className='sm:text-4xl font-extrabold text-cyan-500'>Como o </span>
                        <span className='sm:text-4xl font-extrabold text-pink-500'>FonotherApp</span>

                        <div>
                            <span className='sm:text-4xl font-extrabold text-cyan-500'> vai te ajudar nos seus</span>
                        </div>
                        <span className='sm:text-4xl font-extrabold text-pink-500'>objetivos?</span>

                        <div className='flex flex-col'>
                            <span className='sm:text-1xl pb-8'>
                                Com esse aplicativo você pode registrar e acompanhar
                                a evolução do quadro clínico/fonoaudiológico,
                                permitindo a avaliação contínua do paciente.
                            </span>

                            <span className='sm:text-1xl pb-8'>

                                Você ainda vai dispor de vídeos e orientações sobre
                                exercícios que podem melhorar a musculatura envolvida
                                na deglutição, motricidade orofacial e paralisia facial.

                            </span>


                            <span className='sm:text-1xl pb-8'>
                                Com todas as suas funcionalidades, este aplicativo pode
                                revolucionar a sua rotina de atendimentos trazendo
                                otimização do tempo e simplificando processos!
                            </span>

                            <Link  to={"/cadastro"} className='bg-pink-600 p-5 text-white rounded-xl hover:bg-pink-700 '>

                                Quer saber como ele funciona na prática?

                                Clique aqui e faça o cadastro no Plano Gratuito!
                            </Link>

                        </div>
                    </div>

                </div>



            </div>
        </div>

    )
}
